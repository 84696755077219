.info-card {
  background-color: #fff;
  border-top: 8px solid #2b91d7;
}

.info-card__img {
  max-width: 40%;
}

.info-card__footer {
  background-color: #fcf4e4;
}

.info-card__footer-number {
  color: #ee974c;
}

.info-card__keep-btn {
  background-color: #ee974c;
  border: none;
  color: white;
}

.info-card__apply-btn {
  background-color: #2b91d7;
  border: none;
}

.info-card__bg-color-glay {
  background-color: #cfcece;
}
