.info-media__bg-color-white {
  background-color: #fff;
}

.info-media__border-top-bold {
  border-top: 8px solid #2b91d7;
}

.info-media__border-bottom-bold {
  border-bottom: 8px solid #2b91d7;
}

.info-media__border-bottom {
  border-bottom: 4px solid #2b91d7;
}

.info-media__corp-title-glay {
  color: rgb(160, 159, 156);
  font-weight: bold;
  margin-bottom: 0px;
}

.info-media__bold {
  font-weight: bold;
}

.info-media__color--orange {
  color: #ee974c;
}

.info-media__border--orange {
  border: #ee974c 1px solid;
}

.info-media {
  background-color: #fff;
  border-top: 8px solid #2b91d7;
}

.info-media__img {
  max-width: 40%;
}

.info-media__footer {
  background-color: #fcf4e4;
}

.info-media__footer-number {
  color: #ee974c;
}

.info-media__keep-btn {
  background-color: #ee974c;
  border: none;
}

.info-media__apply-btn {
  background-color: #2b91d7;
  border: none;
}

.info-media__bg-color-glay {
  background-color: #cfcece;
}
